<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

export default {
    page: {
        title: "Daftar Asesmen",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    components: {
        Layout,
        PageHeader,
        // Datatable,
        Pagination,
    },
    data() {
        let sortOrders = []; // select sortOrder

        // definisi kolom yang dipakai
        let columns = [
            { width: "50px", label: "No", name: "id_menu_master" },
            { width: "auto", label: "Nama Menu", name: "menu_master_nama" },
            { width: "100px", label: "Type", name: "menu_master_type" },
            { width: "125px", label: "Icon Menu", name: "menu_master_icon" },
            { width: "auto", label: "Link Path", name: "menu_master_link_path" },
            { width: "auto", label: "Link Name", name: "menu_master_link_name" },
            { width: "80px", label: "Urutan", name: "menu_master_urutan" },
            { width: "75px", label: "Parent", name: "menu_master_parent" },
            { width: "150px", label: "Created At", name: "created_at" },
            { width: "150px", label: "Updated At", name: "updated_at" },
            { width: "100px", label: "Status", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
            { width: "160px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
        ];

        // cek sorting
        columns.forEach((column) => {
            sortOrders[column.name] = -1;
        });

        return {
            title: "Daftar Asesmen",
            items: [
                {
                    text: "Asesmen",
                    href: "/asesmen-asesi",
                },
                {
                    text: "Daftar Asesmen",
                    active: true,
                },
            ],
            namePathUrl: this.$route.name,
            sessionRoleId: localStorage.session_role_active_id,
            // variable Page Table
            loadingTable: true,
            table_data: [],
            columns: columns,
            sortKey: "id_menu_master", // sort key awal
            sortOrders: sortOrders,
            currentTablePage: "",

            // parsing data ke table data
            tableData: {
                draw: 0,
                length: 10,
                search: "",
                column: 0,
                dir: "asc",
                filter_status: true, // untuk filtering data
                skema_sertifikasi_id: null,
                tanggal_dari: null,
                tanggal_sampai: null,
            },

            // config untuk paginasi
            pagination: {
                lastPage: "",
                currentPage: "",
                total: "",
                lastPageUrl: "",
                nextPageUrl: "",
                prevPageUrl: "",
                from: "",
                to: "",
                links: "",
            },
            optionsSkemaSertifikasi: [],
            skema_sertifikasi_selected: null,
            tanggal_dari: null,
            tanggal_sampai: null,
        };
    },
    mounted() {
        this.getDataTable();
        this.getSkemaSertifikasi();
    },
    methods: {
        fullDateTimeFormat(date) {
            return moment(date).format("DD MMMM YYYY");
        },
        filterDatatable() {
            let self = this;
            self.tableData.skema_sertifikasi_id = self.skema_sertifikasi_selected?.skema_id;
            self.tableData.tanggal_dari = self.tanggal_dari;
            self.tableData.tanggal_sampai = self.tanggal_sampai;
            // self.tableData.asesor_id = self.asesi_selected?.user_id;
            self.getDataTable();
        },
        getDataTable(url = process.env.VUE_APP_BACKEND_URL_VERSION + "asesmen") {
            let self = this;
            self.loadingTable = true;
            self.tableData.draw++;

            var config = {
                method: "get",
                url: url,
                params: self.tableData,
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };

            axios(config)
                .then((response) => {
                    let response_data = response.data;
                    let response_data_fix = response_data.data[0];
                    if (response_data.meta.code == 200) {
                        if (self.tableData.draw == response_data_fix.draw) {
                            self.table_data = response_data_fix.list_data.data;
                            // self.configPagination(response_data_fix.list_data);
                            // self.currentTablePage = response_data_fix.list_data.current_page;
                            console.log(response_data_fix.list_data.data);
                            self.loadingTable = false;
                        }
                        self.loadingTable = false;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch((errors) => {
                    console.log(errors);
                });
        },
        getSkemaSertifikasi() {
            // GET SKEMA SERTIFIKASI KATEGORI
            let self = this;

            // load data menu parent
            var config = {
                method: "get",
                url: process.env.VUE_APP_BACKEND_URL_VERSION + "referensi/ref-skema-muk-terbit",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + localStorage.access_token,
                },
            };
            axios(config)
                .then(function (response) {
                    var response_data = response.data;
                    var response_data_fix = response_data.data;
                    if (response_data.meta.code == 200) {
                        self.optionsSkemaSertifikasi = response.data.data.referensi;
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: response_data_fix.data.message,
                        });
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        eksporPdf() {
            let self = this;
            var skema_id = "";
            if (self.skema_sertifikasi_selected?.skema_id) {
                skema_id = self.skema_sertifikasi_selected?.skema_id;
            }
            const link = document.createElement("a");
            link.href = process.env.VUE_APP_BACKEND_URL_VERSION + "public/asesmen/pdf?skema_sertifikasi_id=" + skema_id + "&tanggal_dari=" + self.tanggal_dari + "&tanggal_sampai=" + self.tanggal_sampai;
            link.setAttribute("target", "_blank");
            link.click();
        },
        eksporExcel() {
            let self = this;
            var skema_id = "";
            if (self.skema_sertifikasi_selected?.skema_id) {
                skema_id = self.skema_sertifikasi_selected?.skema_id;
            }
            const link = document.createElement("a");
            link.href = process.env.VUE_APP_BACKEND_URL_VERSION + "public/asesmen/excel?skema_sertifikasi_id=" + skema_id + "&tanggal_dari=" + self.tanggal_dari + "&tanggal_sampai=" + self.tanggal_sampai;
            link.setAttribute("target", "_blank");
            link.click();
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="card-title">Daftar Asesmen</div>
                            </div>
                            <div class="form-group col-md-6 text-end">
                                <div class="text-end">
                                    &nbsp;
                                    <button class="btn btn-sm btn-success m-1" @click="eksporExcel"><i class="fas fa-file-excel"></i> Cetak Excel</button>
                                    <button class="btn btn-sm btn-danger m-1" @click="eksporPdf"><i class="fas fa-file-pdf"></i> Cetak PDF</button>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <hr />
                                <div class="row">
                                    <div class="form-group col-md-2">
                                        <label for="statusTable">Tanggal Dari</label>
                                        <input type="date" v-model="tanggal_dari" id="" class="form-control" />
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label for="statusTable">Tanggal Sampai</label>
                                        <input type="date" v-model="tanggal_sampai" id="" class="form-control" />
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label for="statusTable">Pilih Skema Sertifikasi</label>
                                        <v-select :options="optionsSkemaSertifikasi" label="skema_nama" v-model="skema_sertifikasi_selected" placeholder="Pilih Skema Sertifikasi"></v-select>
                                    </div>
                                    <!-- <div class="form-group col-md-2">
                                        <label for="statusTable">Pilih Asesi</label>
                                        <v-select
                                            :options="optionsAsesi"
                                            label="name"
                                            v-model="asesi_selected"
                                            placeholder="Pilih Nama Asesi"
                                        ></v-select>
                                    </div> -->
                                    <div class="form-group col-md-1 mt-4">
                                        <button class="btn btn-info btn-sm" style="margin-top: 10px !important" v-on:click="filterDatatable"><i class="fas fa-filter"></i> Filter</button>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <div class="col-md-12 mb-2">
                                <div class="row">
                                    <div class="col-md-4">
                                        <table>
                                            <tr>
                                                <td>Show</td>
                                                <td>
                                                    <select class="form-control form-control-sm" id="showpaginatetable" style="width: 100%" v-model="tableData.length" @change="getDataTable()">
                                                        <option value="10" selected>10</option>
                                                        <option value="50">50</option>
                                                        <option value="100">100</option>
                                                        <option value="200">200</option>
                                                        <option value="500">500</option>
                                                    </select>
                                                </td>
                                                <td>Entries</td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div class="col-md-5">&nbsp;</div>
                                    <div class="col-md-3">
                                        <input type="text" class="form-control form-control-sm" id="searchTable" placeholder="Cari Data ..." v-model="tableData.search" @input="getDataTable()" />
                                    </div>
                                </div>
                                <table class="table mb-0 table-bordered table-condensed table-hover mt-2">
                                    <thead class="bg-dark text-center text-white">
                                        <tr>
                                            <th>No</th>
                                            <th>No. Jadwal Sertifikasi</th>
                                            <th>Skema Sertifikasi</th>
                                            <th>Tempat Pelaksanaan</th>
                                            <th>Tanggal Pelaksanaan</th>
                                            <th>Status</th>
                                            <th>Aksi</th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-center">
                                        <tr v-if="loadingTable">
                                            <td colspan="8" class="text-center"><i class="fas fa-spinner fa-spin"></i> Loading...</td>
                                        </tr>
                                        <tr v-else-if="table_data.length == 0">
                                            <td class="text-center" colspan="8">Data Tidak Tersedia</td>
                                        </tr>
                                        <tr v-else v-for="(row_data, key_data) in table_data" :key="key_data">
                                            <td class="text-center">
                                                {{ key_data + 1 }}
                                            </td>
                                            <td>{{ row_data.nomor_asesmen }}</td>

                                            <td>{{ row_data.skema_nama }}</td>
                                            <td>{{ row_data.tuk_nama }}</td>
                                            <td>{{ fullDateTimeFormat(row_data.tanggal_asesmen) }}</td>
                                            <td>
                                                <span class="badge bg-warning" v-if="row_data.status_id == '8b8ef94a-55f2-11ee-91f0-0242ac110006'"> {{ row_data.status_nama }} </span>
                                                <span class="badge bg-info" v-else> {{ row_data.status_nama }} </span>
                                            </td>
                                            <td class="text-center">
                                                <div class="d-grid gap-1">
                                                    <router-link :to="{ name: 'detail-daftar-asesmen-asesi', params: { id: row_data.id } }" class="btn btn-sm btn-primary bg-primary"> <i class="fas fa-search"></i> Detail </router-link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <pagination :pagination="pagination" @to-page="toPage"></pagination>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
